<template>
  <v-container class="fill-height bg" fluid>
    <!-- Normal Login Widget -->
    <v-fade-transition mode="out-in">
      <v-row align="center" justify="center" v-show="login_method === 'normal'">
        <v-col cols="12" lg="5" md="8" sm="8">
          <v-card elevation="24">
            <v-row align="center" justify="center" no-gutters>
              <!-- Login Form -->
              <v-col cols="12" style="padding: 3vw 6vw 3vw 6vw">
                <v-card flat>
                  <!-- Input fields -->
                  <v-card-text class="py-2">
                    <v-row align="center" justify="center" no-gutters>
                      <v-img :src="require('@/assets/images/logo.png')" max-height="6vh" max-width="6vh" contain class="mb-12"></v-img>
                    </v-row>

                    <v-text-field @keypress.enter="login" v-model="username" placeholder=" " color="rgb(206, 45, 45)">
                      <template v-slot:label>
                        <label class="inputField">USERNAME</label>
                      </template>
                    </v-text-field>

                    <v-text-field @keypress.enter="login" v-model="password" placeholder=" " type="password" color="rgb(206, 45, 45)">
                      <template v-slot:label>
                        <label class="inputField">PASSWORD</label>
                      </template>
                    </v-text-field>
                  </v-card-text>

                  <!-- button -->
                  <v-card-actions class="pl-4">
                    <v-row no-gutters>
                      <v-col cols="6" class="pr-3">
                        <v-btn rounded block large class="loginButton" dark @click="login">LOGIN</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn outlined rounded block large class="accountButton" @click="switchLoginMethod">AIA Account</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>

                  <v-card-subtitle>
                    <v-row no-gutters align="center" justify="center">
                      Forgotten your login details?
                      <a href="mailto:" class="getHelpURL">Get Help!</a>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-fade-transition>

    <!-- OKTA Login Widget -->
    <v-fade-transition mode="out-in">
      <v-row align="center" justify="center" v-show="login_method === 'sso'">
        <div id="okta-signin-container">
          <v-btn @click="switchLoginMethod" style="position: absolute; top: 10px; left: 10px; z-index: 10">
            <v-icon left>fas fa-arrow-left</v-icon>
            Back
          </v-btn>
        </div>
      </v-row>
    </v-fade-transition>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'

export default {
  name: 'Login',
  data: () => ({
    login_method: 'normal',
    username: null,
    password: null
  }),
  methods: {
    ...mapActions(['authenticateUser']),
    ...mapMutations(['SET_SNACKBAR']),
    switchLoginMethod() {
      const switch_to = this.login_method === 'normal' ? 'sso' : 'normal'
      this.login_method = ''
      setTimeout(() => {
        this.login_method = switch_to
      }, 300)
    },
    login() {
      this.authenticateUser({ username: this.username, password: this.password })
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.widget = new OktaSignIn({
        logo: require('@/assets/images/logo.png'),
        baseUrl: process.env.VUE_APP_OKTA_BASE_URL,
        clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
        redirectUri: process.env.VUE_APP_OKTA_REDIRECT_URI,
        authParams: {
          pkce: true,
          issuer: process.env.VUE_APP_OKTA_ISSUER,
          display: 'page',
          scopes: ['openid', 'profile', 'email']
        }
      })
      this.widget.showSignInAndRedirect({ el: '#okta-signin-container' })
    })
  },
  destroyed() {
    // Remove the widget from the DOM on path change
    this.widget.remove()
  }
}
</script>

<style scoped>
.bg {
  background: #f5f5f5 !important;
}

.inputField {
  letter-spacing: 1px;
  font-weight: 500;
}

.loginButton {
  background: linear-gradient(50deg, rgba(206, 45, 45, 1) 0%, rgba(205, 47, 97, 1) 63%) !important;
}

.accountButton {
  color: rgb(206, 45, 45) !important;
}

.getHelpURL {
  text-decoration: none;
  margin-left: 2px;
  font-weight: 800;
  color: rgb(206, 45, 45) !important;
}
</style>
